<template>
	<section class="invoice-add-wrapper">
	  <b-row class="invoice-add">
  
		<!-- Col: Left (Invoice Container) -->
		<b-col
		  cols="8"
		  xl="8"
		  md="8"
		>
		  <b-form @submit.prevent>
			<b-card
			  no-body
			  class="invoice-preview-card"
			>
			  <!-- Header -->
			  <b-card-body class="invoice-padding pb-0">
                <div class="d-flex align-items-center mt-2">
					  <span class="title font-weight-bold" style="width: 15% !important;">
						<feather-icon icon="CalendarIcon" class="" size="16"/> Fecha:
					  </span>
					  <div style="width: 85% !important;">
						<!-- Select Client -->
						<label class="font-weight-bold" style="font-size: 20px;">{{invoiceData.fechalabel}}</label> 
					  </div>
					
				  </div>
				  <div class="d-flex align-items-center mt-2">
					  <span class="title font-weight-bold" style="width: 15% !important;">
						<feather-icon icon="SpeakerIcon" class="" size="16"/> Lector: <span v-if="lector != null">✅</span><span v-if="lector == null">⚠️</span>
					  </span>
					  <div style="width: 85% !important;" v-if="lectores == null">
						<span class="text-danger fw-light"> No se detectó ningun lector de huellas</span>
					  </div>
					  <div style="width: 85% !important;" v-if="lectores != null">
						<b-form-select v-model="lector" :options="lectores" @change="seleccionar_lector($event)"></b-form-select>
					  </div>
				  </div>
				<div class="d-flex align-items-center mt-2">
					  <span class="title font-weight-bold" style="width: 15% !important;">
						<feather-icon icon="GridIcon" class="" size="16"/> Malla:
					  </span>
					  <div style="width: 85% !important;">
						<!-- Select Client -->
						<v-select
							v-model="invoiceData.centro_costo_padre_id"
							:options="mallas"
							@input="getCentrosTrabajo()"
							label="Nombre"
							:clearable="false"
							></v-select>
					  </div>
					
				  </div>
				<div class="d-flex align-items-center mt-2">
					  <span class="title font-weight-bold" style="width: 15% !important;">
						<feather-icon icon="LayersIcon" class="" size="16"/> Centro de Trabajo:
					  </span>
					  <div style="width: 85% !important;">
						<!-- Select Client -->
						<v-select
							v-model="invoiceData.centro_costo_id"
							:options="centros_trabajo"
							@input="actualizarPagos()"
							label="Nombre"
							input-id="invoice-data-client"
							:clearable="false"
							></v-select>
					  </div>
					
				  </div> 
                  <br>
                    <pre class="font-weight-bold text-center text-primary" style="margin-bottom: -10px;"  v-if="invoiceData.centro_costo_id != null && false">
                        <b><br>CHECADOR ESTABLECIDO EN EL CENTRO DE TRABAJO</b>
                    </pre>
				  <pre class="row d-flex justify-content-around"  v-if="invoiceData.centro_costo_id != null && false">
                    <div class="col-4 text-center">
						<b class="mt-1">COD:</b> {{invoiceData.centro_costo_id.Codigo}} 
					</div>
					<div class="col-4">
						<b class="mt-1">NOMBRE:</b> {{invoiceData.centro_costo_id.Nombre}} 
					</div>
					<div class="col-4">
						<b class="mt-1">PAGO POR HORA:</b>
					</div>
				  </pre>
				
			  </b-card-body>
  
			  <!-- Spacer -->
			  <hr class="invoice-spacing">
  
			  <!-- Items Section -->
			  <b-card-body class="invoice-padding form-item-section" v-if="invoiceData.centro_costo_id != null && false">
				<div ref="form" class="repeater-form" :style="{height: trHeight}">
				  <b-row
					v-for="(item, index) in invoiceData.items"
					:key="index"
					ref="row"
					class="pb-2"
				  >
  
					<!-- Item Form -->
					<!-- ? This will be in loop => So consider below markup for single item -->
					<b-col cols="12">
  
					  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
					  <div class="d-none d-lg-flex">
						<b-row class="flex-grow-1 px-1">
						  <!-- Single Item Form Headers -->
						  <b-col
							cols="12"
							lg="8"
						  >
						  <feather-icon icon="UserIcon" class="" size="16"/> Empleado
						  </b-col>
						  <b-col
							cols="12"
							lg="4"
                            class="text-center"
						  >
						  <feather-icon icon="ClockIcon" class="" size="16"/>  Acciones
						  </b-col>
                         
						</b-row>
						<div class="form-item-action-col" />
					  </div>
  
					  <!-- Form Input Fields OR content inside bordered area  -->
					  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
					  <div class="d-flex border rounded">
						<b-row class="flex-grow-1 p-2">
						  <!-- Single Item Form Headers -->
						  <b-col
							cols="12"
							lg="8"
						  >
							<label class="d-inline d-lg-none">Empleado</label>
							<v-select
							  v-model="item.empleado"
							  :options="itemsEmpleados"
							  label="Nombre"
							  :clearable="true"
							  class="mb-2 item-selector-title"
							  placeholder="Seleccionar Empleado"
							  @input="val => updateItemForm(index, val)"
							/>
						  </b-col>
						 
						  <b-col
							cols="12"
							lg="2"
                            v-if="item.empleado"
						  >
							<button class="btn btn-success btn-sm" @click="checarEntrada(item)">
                                <div class="d-flex justify content-between">
                                    <feather-icon icon="LogInIcon" class="mr-1" size="22"/> 
                                      Checar Entrada
                                </div>
                            </button>
						  </b-col>
                          <b-col
							cols="12"
							lg="2"
                            v-if="item.empleado"
						  ><button class="btn btn-danger btn-sm" @click="checarSalida(item)">
                                <div class="d-flex justify content-between">
                                    <feather-icon icon="LogOutIcon" class="mr-1" size="22"/> 
                                      Checar Salida
                                </div>
                            </button>
						  </b-col>
						 
						</b-row>
						<!-- <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
						  <feather-icon
							size="16"
							icon="XIcon"
							class="cursor-pointer"
							@click="removeItem(index)"
						  />
						 
						</div> -->
					  </div>
					</b-col>
				  </b-row>
				</div>
			  </b-card-body>
  
			
  
			  <!-- Spacer -->
			  <hr class="invoice-spacing">
  
			  <!-- Note -->
			  <b-card-body class="invoice-padding pt-0">
				<b-table
                    stacked="md"
                    show-empty
                    small
                    class="position-relative"
                    empty-text="No se encontraron registros"
                    :items="data"
                    :fields="columns"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                >
                    <template #cell(folio)="data">
                        <b class="text-primary">#{{data.item.folio}}</b>
                    </template>

                    <template #cell(fecha)="data">
                        <div class="text-wrap"> 
                            <span class="align-text-top">{{ formatDate(data.item.fecha) }}</span>
                        </div>
                    </template>

                    <template #cell(empleado)="data">
                        <b-media vertical-align="center">
                            <template #aside>
                            <b-avatar
                                size="30"
                                :text="avatarText(data.value.nombre_completo)"
                                :variant="`light-primary`"
                                :to="{ /*name: 'apps-users-view', params: { id: data.item.id } */}"
                            />
                            </template>
                            <span
                            :to="{ /*name: 'apps-users-view', params: { id: data.item.id }*/ }"
                            :class="`text-primary`"
                            class="font-weight-bold d-block text-nowrap"
                            >{{ data.value.nombre_completo }}
                            </span>
                        </b-media>
                    </template>

                    <template #cell(pago)="data">
                        <div class="text-wrap"> 
                            <span class="align-text-top font-weight-bold text-success">{{ formatMoney(data.item.pago) }}</span>
                        </div>
                    </template>

                    <template #cell(horas_trabajadas)="data">
                        <div class="text-wrap"> 
                            <span class="align-text-top text-dark">{{ data.item.horas_trabajadas }}</span>
                        </div>
                    </template>
                
                    <!-- Column: User -->
                    <template #cell(centro_costo)="data">
                        {{ data.value.malla.nombre }} - {{ data.value.nombre }}
                    </template>
            
                    <template #cell(acciones)="data">
                        <div class="text-nowrap">
                            <b-dropdown
                                variant="link"
                                no-caret
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                                </template>
                                <b-dropdown-item
                                    :to="{name: 'mov-ajuste-fecha-antiguedad-editar', params: { id: data.item.Id }, }"
                                >
                                    <feather-icon icon="EditIcon" class="text-warning" />
                                    <span class="align-middle ml-50">Editar</span>
                                </b-dropdown-item>
                                <b-dropdown-item  @click="desactivarMovimiento(data.item)">
                                    <feather-icon icon="TrashIcon" class="text-danger" />
                                    <span class="align-middle ml-50">Eliminar</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
			  </b-card-body>
			</b-card>
            <!-- Paginacion -->
		<div class="mx-2 mb-2">
			<b-row>
				<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
					<span class="text-muted">
						Mostrando {{ dataMeta.from }} de {{ dataMeta.to }} de {{ dataMeta.of }} registros
					</span>
				</b-col>
				<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
					<b-pagination class="mb-0 mt-1 mt-sm-0"
						v-model="currentPage"
						:total-rows="totalRows"
						:per-page="perPage"
						first-number
						last-number
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</div> <!-- Paginacion -->
		  </b-form>
		</b-col>
  
		<b-col
		  cols="12"
		  md="4"
		  xl="3"
		  class="invoice-actions mt-md-0 mt-2 text-center"
		>
  
		  <b-card>
  
			<div class="text-center">
				<img :src="this.huella_dact_img" alt="" width="200px" class="p-1 img-thumbnail rounded-circle" id="img_huella" name="img_huella">
				<h6 class="text-success mt-1">Presione su dedo en el lector ...</h6>
			</div>
		  </b-card>
		  <a rel="stylesheet" href="https://drive.google.com/file/d/1lh_xvsPjNmUCTiaNzPl5qAiY6rABXcs4/view?usp=drive_link" download class="small"> <i class="bi bi-download"></i> Descargar Controlador del Lector</a>

		</b-col>
		<pre :hidden="true">
			{{invoiceData}}
		</pre>
	  </b-row>
	</section>
  </template>
  
  <script>
  import router from '@/router'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Logo from '@core/layouts/components/Logo.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { heightTransition } from '@core/mixins/ui/transition'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store'
  import {
	BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle, BTable, BPagination,BMedia, BFormSelect,
			BAvatar
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import vSelect from 'vue-select'
  import flatpickr1 from "flatpickr";
	import { Spanish } from "flatpickr/dist/l10n/es.js"
	flatpickr1.localize(Spanish); // default locale is now Spanish
  import flatPickr from 'vue-flatpickr-component'
  import invoiceStoreModule from '../../apps/invoice/invoiceStoreModule'
  import axios from '@axios'
  import moment from 'moment'
  
  //import { FingerprintReader } from "@digitalpersona/devices";

  import huella_img from "../../../assets/images/0.gif"

  const dtf = new Intl.DateTimeFormat("es-ES", {
  calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  timeZone: "America/Mazatlan"
});

  export default {
	components: {
		ToastificationContent,
		BFormSelect,
		BRow,
        BPagination,
        BTable,
        BMedia,
		BAvatar,
		BCol,
		BCard,
		BCardBody,
		BButton,
		BCardText,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BFormTextarea,
		BFormCheckbox,
		BPopover,
		flatPickr,
		vSelect,
		Logo,
	},
	directives: {
		Ripple,
		'b-toggle': VBToggle, 
	},
	mixins: [heightTransition],
    computed: {
			dataMeta() {
				return {
					from: this.perPage * (this.currentPage - 1) + 1,
					to: (this.perPage*this.currentPage) < this.totalRows ? this.perPage * this.currentPage : this.totalRows,
					of: this.totalRows,
				}
    		}
  		},
	async mounted() {
		//testing libreria dp
		//let fpreader = new FingerprintReader();
		//testing
		let _instance = this;
		this.sdk.onDeviceConnected = function (e) {
            // Detects if the device is connected for which acquisition started
            //showMessage("Scan Appropriate Finger on the Reader", "success");
			console.log("Dispositivo conectado");
          _instance.lector_status = "Dispositivo conectado";
          _instance.cargarLectores();
        };
        this.sdk.onDeviceDisconnected = function (e) {
            // Detects if device gets disconnected - provides deviceUid of disconnected device
            //showMessage("Device is Disconnected. Please Connect Back");
			    console.log("Dispositivo desconectado");
          _instance.lector_status = "Dispositivo desconectado";    
          _instance.cargarLectores();
        };
        this.sdk.onCommunicationFailed = function (e) {
            // Detects if there is a failure in communicating with U.R.U web SDK
            //showMessage("Communication Failed. Please Reconnect Device")
            console.log("Communication Failed. Please Reconnect Device")
            _instance.lector_status = "Communication Failed. Please Reconnect Device";
        };
        this.sdk.onSamplesAcquired = function (s) {
            // Sample acquired event triggers this function
			//console.warn(s);
			//let img = document.getElementById("img_huella");
			//img.src = "data:image/png;base64," + finger;
			/* console.warn(_instance.ruta);
			if(_instance.ruta == "checador-registro") */
			_instance.verifySample(s);
			//alert(s.samples[0]);
        };
        this.sdk.onQualityReported = function (e) {
            // Quality of sample acquired - Function triggered on every sample acquired
            //document.getElementById("qualityInputBox").value = Fingerprint.QualityCode[(e.quality)];
        }
		await this.cargarLectores();
		//await this.getLectores(),
		this.getMallas(),
		this.initTrHeight(),
		//this.getCentrosTrabajo(),
		this.getEmpleados(),
        setInterval(() => {
            this.invoiceData.fecha = moment().format('YYYY-MM-DD HH:mm:ss');
            this.invoiceData.fechalabel = new Date().toLocaleTimeString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',  }).toUpperCase();
            //this.invoiceData.fechalabel = new moment().locale('es').format('LLL');
        }, 1000);
	},
	created() {
	  	window.addEventListener('resize', this.initTrHeight)
    },
	destroyed() {
	  	window.removeEventListener('resize', this.initTrHeight)
		this.detener_captura();
	},
	data() {
	  return {
		ruta : router.currentRoute.name,
		verify_result : null,
		huella_dact_img : null,
		currentFormat : Fingerprint.SampleFormat.Intermediate,//Fingerprint.SampleFormat.PngImage,
		sdk : new Fingerprint.WebApi,
		//sdk_test : this.FingerprintSdkTest(),
		operationToRestart : null,
		acquisitionStarted : false,
		//sdk : null,
		capturando: false,
		lectores: [],
      	lector: null,
		mallas : [],
		centros_trabajo: [],
		itemsEmpleados : [],
        data: null,
				columns: [
					{ key: 'fecha', label: 'Fecha', sortable: true },
					{ key: "empleado", label: 'Empleado', sortable: true },
					{ key: 'centro_costo', label: 'Centro Trabajo', sortable: true },
					{ key: 'hora_entrada', label: 'Horas Entrada', sortable: true },							
					{ key: 'hora_salida', label: 'Hora Salida', sortable: true },	
				],
				totalRows: 0,
				currentPage: 1,
				perPage: 50,
				pageOptions: [5, 10, 25, 50, 100],
				filter: null,
	  }
	},
	methods: {
	seleccionar_lector(event){
		this.acquisitionStarted = false;
		this.iniciar_captura();
	},
	iniciar_captura(){
		if (this.acquisitionStarted) // Monitoring if already started capturing
            return;
        let _instance = this;
        //showMessage("");
		console.log("se inicio captura en el lector" + this.lector)
        this.operationToRestart = this.startCapture;
        this.sdk.startAcquisition(Fingerprint.SampleFormat.Intermediate, this.lector).then(function () {
            _instance.acquisitionStarted = true;

            //Disabling start once started
            //disableEnableStartStop();

        }, function (error) {
            //showMessage(error.message);
            console.log(error.message)
        });
	},
	detener_captura(){
		if (!this.acquisitionStarted) //Monitor if already stopped capturing
            return;
        let _instance = this;
        //showMessage("");
        console.log("se detuvo captura en el lector " + this.lector);
        this.sdk.stopAcquisition().then(function () {
            _instance.acquisitionStarted = false;

            //Disabling stop once stopped
            //disableEnableStartStop();

        }, function (error) {
            //showMessage(error.message);
            console.log(error.message)
        });
	},
	verifySample(sample){
		let samples = JSON.parse(sample.samples);
		console.log(samples[0]);
		//let finger = Fingerprint.b64UrlTo64(samples[0]);
		//this.huella_dact_img = "data:image/png;base64," + finger;
		//call axios service
		this.invoiceData.huella = samples[0].Data;
		

		axios.post('/api/checador/verify', this.invoiceData ).then(
			(response) => {
				this.verify_result = response.data.data;
				//if response status is 201 then checada is not registered
				if(response.status == 201){
					this.$toast({
						component: ToastificationContent,
						props: {
						variant : 'success',
						title : "Huella identificada correctamente",
						text: response.data.data.nombre_completo,
						icon: 'CheckSquareIcon',
						},
					});
					//guardar el registro de la checada
					let payload = {
						empleado_id: response.data.data.id,
						fecha: this.invoiceData.fecha,
						centro_costo_id: this.invoiceData.centro_costo_id.Codigo,
						tipo : 'lector'
					};

					axios.post('/api/movimientos/checar-entrada', payload).then(
						(response) => {
						//if response status is 201 then checada is not registered
							if(response.status == 201){
								this.cargaMovimientos();
							}
							else if (response.status == 202){
								this.$toast({
								component: ToastificationContent,
								props: {
								variant : 'danger',
								title: "No se pudo registrar entrada o salida",
								text: response.data,
								icon: 'ALertTriangleIcon',
								},
								timeout: 5000,
								
							})
							}
						}
					).catch(
						(error) => {
							this.$toast({
								component: ToastificationContent,
								props: {
								variant : 'danger',
								title: "Alerta",
								text: error.error,
								icon: 'ALertTriangleIcon',
								},
							})
						}
					);

				}
				else if (response.status == 203){
					this.$toast({
						component: ToastificationContent,
						props: {
						variant : 'danger',
						title: response.data.data,
						text: 'No se encontró la huella capturada en el sistema',
						icon: 'SlashIcon',
						},
					})
				}
			}
		).catch(
			(err) => {
				if(err.response.status === 422){
                      //console.log(err.response.data.errors);
                      //console.log(err.response.data.errors.email[0]);
                      //console.log(err.response.data.errors.password[0]);
                      //console.log(err.response.da
					this.$toast({
						component: ToastificationContent,
						props: {
						variant : 'danger',
						title: "Centro de Trabajo no seleccionado",
						text: 'Por favor seleccione un centro de trabajo para registrar la entrada o salida del empleado',
						icon: 'ALertTriangleIcon',
						},
					})
                      
                }
			}
		);
	},
	readyForIdentify(){
    	return (this.lector !== null);
	},
	 async cargarLectores(){
		this.lectores = [];
		let rd = this.sdk.enumerateDevices();
		rd.then((readers) => {
		if(readers.length > 0){
			//this.lectores = availableReaders;
			readers.forEach(reader => {
				this.lectores.push(reader);
			});
			this.lector = this.lectores[0];
			//this.myReader.reader.startCapture();
			this.iniciar_captura();
			this.huella_dact_img = huella_img;
		}
		else{
			//alert("Please Connect the Fingerprint Reader")
			this.lector = null;
		}
		}).catch((error) => {
			console.log(error);
			this.lector = null;
		});
	}, 
    async cargaMovimientos() {
            let centro_trabajo = this.invoiceData.centro_costo_id.Codigo;
            const r = await axios.get(`/api/movimientos?q=${moment().format('YYYY-MM-DD')}&ct=${centro_trabajo}`)
            this.data = r.data.data.data ? r.data.data.data : [];
            this.totalRows = this.data.length
            //get only the rows of the current date
            /* this.data = this.data.filter(
                (item) => {
                    return moment(item.fecha).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
                }
            ); */

        },
        onFiltered(filteredItems) {
				this.currentPage = 1
				this.totalRows = filteredItems.length
			},
        formatDate(date) {
				if (!date) return null
				return moment(date).format('DD/MM/YYYY')
			},
	 actualizarPagos(){
        this.cargaMovimientos();
		//recorrer todos los items que se han agregado
		/* this.invoiceData.items.forEach(
			(item) => {
				item.pago = item.horas_trabajadas * this.invoiceData.centro_costo_id.Pago;
			}
		);	 */
	},
	guardarMovimiento(){
		axios.post('/api/movimientos', this.invoiceData).then(
			(response) => {
				this.$toast.success('Movimiento guardado correctamente');
				//return to back
				this.$router.push('/movimientos/captura-movimientos');
			}
		).catch(
			(error) => {
				this.$toast.error('Ocurrio un error al guardar el movimiento');
			}
		);
	},
	calcularTotalPago(item){
		//tomar el valor de las horas que se estan escribiendo
		let horas = item.horas_trabajadas;
		if(this.invoiceData.centro_costo_id == null){
			this.$toast.error('Selecciona un centro de trabajo para calcular el pago');
			return;
		}

		//calcular el total del pago
		let pago = horas * this.invoiceData.centro_costo_id.Pago;

		//asignar el total del pago
		item.pago = pago;

	},
	getMallas() {
      axios.get('/api/cat-mallas')
        .then(response => {
          this.mallas = response.data.data;
		 
        })
    },
	getCentrosTrabajo(){
		axios.get('/api/cat-centros-costo/by-malla/' + this.invoiceData.centro_costo_padre_id.Codigo)
        .then((response) => {
          this.centros_trabajo = response.data.data;
        })
        .catch((error) => {
          console.log(error)
        })
	},
	getEmpleados(){
		axios.get('/api/cat-empleados')
        .then((response) => {
          this.itemsEmpleados = response.data.data;
        })
        .catch((error) => {
          console.log(error)
        })
	},
    checarEntrada(item){
        let payload = {
            empleado_id: item.empleado.Codigo,
            fecha: this.invoiceData.fecha,
            centro_costo_id: this.invoiceData.centro_costo_id.Codigo,
			tipo : 'entrada'
        };

        axios.post('/api/movimientos/checar-entrada', payload).then(
            (response) => {
               //if response status is 201 then checada is not registered
				if(response.status == 201){
					this.$toast.success('checada registrada correctamente');
					//return to back
					//this.$router.push('/movimientos/captura-movimientos');
					this.cargaMovimientos();
				}
				else if (response.status == 202){
					this.$toast.error(response.data);
				}
            }
        ).catch(
            (error) => {
                this.$toast.error(error.error);
            }
        );
        
    },
    checarSalida(item){
        let payload = {
            empleado_id: item.empleado.Codigo,
            fecha: this.invoiceData.fecha,
            centro_costo_id: this.invoiceData.centro_costo_id.Codigo,
			tipo : 'salida'
        };

        axios.post('/api/movimientos/checar-entrada', payload).then(
            (response) => {
				//if response status is 201 then checada is not registered
				if(response.status == 201){
					this.$toast.success('salida registrada correctamente');
					//return to back
					//this.$router.push('/movimientos/captura-movimientos');
					this.cargaMovimientos();
				}
				else if (response.status == 202){
					this.$toast.error(response.data);
				}
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        );
    },
	formatMoney(value) {
				//format to mexican currency
				var formatter = new Intl.NumberFormat('es-MX', {
				style: 'currency',
				currency: 'MXN',
				minimumFractionDigits: 2
				});

				return formatter.format(value);
			},
	  addNewItemInItemForm() {
		this.$refs.form.style.overflow = 'hidden'
		this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
  
		this.$nextTick(() => {
		  this.trAddHeight(this.$refs.row[0].offsetHeight)
		  setTimeout(() => {
			this.$refs.form.style.overflow = null
		  }, 350)
		})
	  },
	  removeItem(index) {
		this.invoiceData.items.splice(index, 1)
		this.trTrimHeight(this.$refs.row[0].offsetHeight)
	  },
	  initTrHeight() {
		this.trSetHeight(null)
		this.$nextTick(() => {
		  //this.trSetHeight(this.$refs.form.scrollHeight)
		})
	  },
	},
	setup() {
	  const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
  
	  // Register module
	  if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
  
	  // UnRegister on leave
	  onUnmounted(() => {
		if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
	  })
  
	  /*const clients = ref([])
	  store.dispatch('app-invoice/fetchClients')
		.then(response => { clients.value = response.data })*/
  
	  const itemFormBlankItem = {
		empleado: null,
		horas_trabajadas: null,
		pago: 0,
		observacion: '',
	  }
  
	  const invoiceData = ref({
		id: null,
		fecha: new Date(),
		centro_costo_padre_id : null,
		centro_costo_id: null,
  
		// ? Set single Item in form for adding data
		items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
  		observaciones: '',
	  })
  
	  const updateItemForm = (index, val) => {
		const { cost, qty, description } = val
		invoiceData.value.items[index].cost = cost
		invoiceData.value.items[index].qty = qty
		invoiceData.value.items[index].description = description
	  }
  
	  const paymentMethods = [
		'Bank Account',
		'PayPal',
		'UPI Transfer',
	  ]
  
	  return {
		invoiceData,
		//clients,
		//itemsOptions,
		updateItemForm,
		itemFormBlankItem,
		paymentMethods,
        avatarText,
	  }
	},
	
  }

//let currentFormat = Fingerprint.SampleFormat.PngImage;

/*let FingerprintSdkTest = (function () {
    function FingerprintSdkTest() {
        let _instance = this;
        this.operationToRestart = null;
        this.acquisitionStarted = false;
        // instantiating the fingerprint sdk here
        this.sdk = new Fingerprint.WebApi;
        this.sdk.onDeviceConnected = function (e) {
            // Detects if the device is connected for which acquisition started
            //showMessage("Scan Appropriate Finger on the Reader", "success");
            console.log("Dispositivo conectado")
        };
        this.sdk.onDeviceDisconnected = function (e) {
            // Detects if device gets disconnected - provides deviceUid of disconnected device
            //showMessage("Device is Disconnected. Please Connect Back");
            console.log("Dispositivo desconectado")    
        };
        this.sdk.onCommunicationFailed = function (e) {
            // Detects if there is a failure in communicating with U.R.U web SDK
            //showMessage("Communication Failed. Please Reconnect Device")
			console.log("Communication Failed. Please Reconnect Device")
        };
        this.sdk.onSamplesAcquired = function (s) {
            // Sample acquired event triggers this function
            storeSample(s);
			//console.warn(sample);
        };
        this.sdk.onQualityReported = function (e) {
            // Quality of sample acquired - Function triggered on every sample acquired
            //document.getElementById("qualityInputBox").value = Fingerprint.QualityCode[(e.quality)];
			console.warn("calidad:",Fingerprint.QualityCode[(e.quality)]);
        }
    }

    // this is were finger print capture takes place
    FingerprintSdkTest.prototype.startCapture = function () {
        if (this.acquisitionStarted) // Monitoring if already started capturing
            return;
        let _instance = this;
        //showMessage("");
        console.log("startCapture")
        this.operationToRestart = this.startCapture;
        this.sdk.startAcquisition(currentFormat, "").then(function () {
            _instance.acquisitionStarted = true;

            //Disabling start once started
            //disableEnableStartStop();

        }, function (error) {
            //showMessage(error.message);
            alert(error.message)
        });
    };
    
    FingerprintSdkTest.prototype.stopCapture = function () {
        if (!this.acquisitionStarted) //Monitor if already stopped capturing
            return;
        let _instance = this;
        //showMessage("");
        alert("Scan Appropriate Finger on the Reader")
        this.sdk.stopAcquisition().then(function () {
            _instance.acquisitionStarted = false;

            //Disabling stop once stopped
            //disableEnableStartStop();

        }, function (error) {
            //showMessage(error.message);
            alert(error.message)
        });
    };

    FingerprintSdkTest.prototype.getInfo = function () {
        let _instance = this;
        return this.sdk.enumerateDevices();
    };

    FingerprintSdkTest.prototype.getDeviceInfoWithID = function (uid) {
        let _instance = this;
        return  this.sdk.getDeviceInfo(uid);
    };
    
    return FingerprintSdkTest;
})();

class Reader{
    constructor(){
        this.reader = new FingerprintSdkTest();
        this.selectFieldID = null;
        this.currentStatusField = null;
   
        this.currentHand = null;
    }

    readerSelectField(selectFieldID){
        this.selectFieldID = selectFieldID;
    }

    setStatusField(statusFieldID){
        this.currentStatusField = statusFieldID;
    }

    displayReader(){
        let readers = this.reader.getInfo();  // grab available readers here
		return readers;
    }
}

class Hand{
	constructor(){
		this.id = 0;
		this.index_finger = [];
		this.middle_finger = [];
	}

	addIndexFingerSample(sample){
		this.index_finger.push(sample);
	}

	addMiddleFingerSample(sample){
		this.middle_finger.push(sample);
	}

	generateFullHand(){
		let id = this.id;
		let index_finger = this.index_finger;
		let middle_finger = this.middle_finger;
		return JSON.stringify({id, index_finger, middle_finger});
	}
}*/

  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .invoice-add-wrapper {
	.add-new-client-header {
	  padding: $options-padding-y $options-padding-x;
		color: $success;
  
	  &:hover {
		background-color: rgba($success, 0.12);
	  }
	}
  }
  </style>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '~@core/scss/base/components/variables-dark';
  
  .form-item-section {
  background-color:$product-details-bg;
  }
  
  .form-item-action-col {
	width: 27px;
  }
  
  .repeater-form {
	// overflow: hidden;
	transition: .35s height;
  }
  
  .v-select {
	&.item-selector-title,
	&.payment-selector {
	  background-color: #fff;
  
	  .dark-layout & {
		background-color: unset;
	  }
	}
  }
  
  .dark-layout {
	.form-item-section {
	  background-color: $theme-dark-body-bg;
  
	  .row .border {
		background-color: $theme-dark-card-bg;
	  }
  
	}
  }
  </style>
  